<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0" >
        <span @click="$router.go(-1)" >
          <b-button
              variant="white"
              class="btn-icon rounded-circle mr-50 text-danger bg-white"
              size="sm"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span> Listado de trabajos
      </h2>
    </div>
    <b-row v-if="projectPrepare" >
      <b-col cols="12" >
        <b-card class="height-card" >
          <b-row class="position-relative" >
            <b-col xl="3" md="6">
              <p class="mb-50 title-style" >NOMBRE DE LA OBRA</p>
              <p class="font-weight-bolder" >{{ project.name }}</p>
            </b-col>
            <b-col xl="3" md="6">
              <p class="mb-50 title-style" >CLIENTE</p>
              <p class="font-weight-bolder" v-if="project.client" ><b-link :to="{ name: 'viewClient', params: { id: project.client.id} }" >{{ project.client.name }}</b-link></p>
            </b-col>
            <b-col xl="3" md="6">
              <p class="mb-50 title-style" >FECHA DE INICIO - FIN</p>
              <p class="font-weight-bolder"><span v-if="project.date_ini" >{{ TimestampToFlatPickr(project.date_ini) }}</span><span v-else> dd/mm/aa </span><span v-if="project.date_fin" > - {{ TimestampToFlatPickr(project.date_fin) }}</span><span v-else> dd/mm/aa </span></p>
            </b-col>
            <b-col xl="3" md="6">
              <p class="mb-50 title-style" >HORAS IMPUTADAS</p>
              <p class="font-weight-bolder" >{{ project.total_hours }}</p>
            </b-col>
            <span
              :class="collapseInfo ? 'text-danger collapse-button' : 'text-danger collapse-button collapsed'"
              :aria-expanded="collapseInfo ? 'true' : 'false'"
              aria-controls="collapse-info"
              @click="collapseInfo = !collapseInfo"
            >
              <feather-icon
                :icon="collapseInfo ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                size="22"
              />
            </span>
            <b-col cols="12" >
              <b-collapse id="collapse-info" v-model="collapseInfo" class="mt-2">
                <b-row>
                  <b-col xl="3" md="6">
                    <p class="mb-50 title-style" >ESTADO</p>
                    <p>
                      <b-badge
                        :variant="statusProjectsVariant[project.status]"
                      >
                        {{ statusProjectsText[project.status] }}
                      </b-badge>
                    </p>
                  </b-col>
                  <b-col xl="3" md="6">
                    <p class="mb-50 title-style" >CÓDIGO OBRA</p>
                    <p class="font-weight-bolder" >{{ project.SKU }}</p>
                  </b-col>
                  <b-col xl="6" md="12">
                    <p class="mb-50 title-style" >DESCRIPCIÓN</p>
                    <div v-if="project.description== '' || project.description == null || project.description == 'null' || project.description == '<p>null</p>'">
                      -
                    </div>
                    <div v-else v-html="project.description" ></div>
                  </b-col>
                  <b-col xl="3" md="6">
                    <p class="mb-50 mt-2 title-style" >RECURSO PREVENTIVO</p>
                    <div v-if="project.recurso_preventivo" > - </div>
                    <div
                        v-for="(item, index) in project.recurso_preventivo"
                        :key="index"
                    >
                      <p class="font-weight-bolder" >{{(JSON.parse(item)).name}} ({{(JSON.parse(item)).phone}})</p>
                    </div>
                  </b-col>
                  <b-col xl="3" md="6">
                    <p class="mb-50 mt-2 title-style" >COORDINADOR DE SEGURIDAD Y SALUD</p>
                    <div v-if="project.person_coordinador_seguridad_salud== '' || project.person_coordinador_seguridad_salud == null">
                      -
                    </div>
                    <p class="font-weight-bolder" v-else>{{ project.person_coordinador_seguridad_salud }} ({{project.phone_coordinador_seguridad_salud}})</p>
                  </b-col>
                  <b-col xl="3" md="6">
                    <p class="mb-50 mt-2 title-style" >COBERTURA DE SEGURO</p>
                    <p>
                      <span v-if="project.date_ini_seguro && project.date_ini_seguro != null" class="font-weight-bolder">{{ TimestampToFlatPickr(project.date_ini_seguro) }}</span>
                      <span v-else> dd/mm/aa </span>
                      <span v-if="project.date_fin_seguro && project.date_fin_seguro != null" class="font-weight-bolder"> - {{ TimestampToFlatPickr(project.date_fin_seguro) }}</span>
                      <span v-else> - dd/mm/aa</span>
                    </p>
                  </b-col>
                  <b-col xl="3" md="6">
                    <p class="mb-50 mt-2 title-style" >APERTURA DE CENTRO DE TRABAJO</p>
                    <p>
                      <span v-if="project.date_ini_centro && project.date_ini_centro != null" class="font-weight-bolder">{{ TimestampToFlatPickr(project.date_ini_centro) }}</span>
                      <span v-else> dd/mm/aa </span>
                      <span v-if="project.date_fin_centro && project.date_fin_centro != null" class="font-weight-bolder"> - {{ TimestampToFlatPickr(project.date_fin_centro) }}</span>
                      <span v-else> - dd/mm/aa </span>
                    </p>
                  </b-col>
                  <b-col xl="3" md="6">
                    <p class="mb-50 mt-2 title-style" >SUBCONTRATA</p>
                    <span v-if="project.subcontrata == 0 || project.subcontrata == true || project.subcontrata == 'true'" >
                        <p class="font-weight-bolder">Sí</p>
                    </span>
                    <span v-else>
                      <p class="font-weight-bolder">No</p>
                    </span>
                  </b-col>
                  <b-col xl="3" md="6" v-if="role == 'admin' || role == 'super_admin'">
                    <p class="mb-50 mt-2 title-style" >IMPORTE FACTURADO</p>
                    <div v-if="project.importe_facturado == ''||project.importe_facturado == null " > - </div>
                    <p v-else class="font-weight-bolder" >{{ project.importe_facturado }} €</p>
                  </b-col>
                  <b-col xl="3" md="6" v-if="role == 'admin' || role == 'super_admin'">
                    <p class="mb-50 mt-2 title-style" >IMPORTE PENDIENTE</p>
                    <div v-if="project.importe_pendiente == ''||project.importe_pendiente == null " > - </div>
                    <p v-else class="font-weight-bolder" >{{ project.importe_pendiente }} €</p>
                  </b-col>
                  <b-col xl="10" md="12">
                    <p class="mb-50 mt-2 title-style" >DOCUMENTOS</p>
                    <div v-if="project.documents.length == 0" > - </div>
                    <b-link
                        v-for="d in project.documents"
                        :key="d.id"
                        :href="d.path"
                        target="_blank"
                    >
                      <b-button
                          variant="outline-primary"
                          size="sm"
                          class="mr-1 mb-50"
                      >
                        {{ d.name }}
                      </b-button>
                    </b-link>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-col>
            <b-col cols="12" class="text-right pr-2">
              <b-link
                  :to="{ name: 'editProject', params: { id: project.id} }"
                  class="d-inline-block text-dark pt-2"
              >
                <span class="">Editar</span>
                <span class=" pl-1"><feather-icon icon="Edit2Icon" /></span>
              </b-link>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" >
        <b-card no-body>
          <div class="d-flex justify-content-between align-items-center" >
            <div class="p-2" >
              <h5 class="m-0" >TRABAJOS DE ESTA OBRA</h5>
            </div>
            <div class="pr-2">
              <b-link
                :to="{ name: 'createJob', params: {project_param: project.id} }"
              >
                <b-button
                  v-if="project.status !== 'complete'"
                  variant="primary"
                  size="sm"
                  class="text-nowrap"
                >
                  {{ 'Añadir nuevo trabajo' }}
                </b-button>
              </b-link>
              <span
                class="ml-1 cursor-pointer"
                @click="visibleFilter = !visibleFilter"
              >
                <feather-icon
                  icon="FilterIcon"
                  size="20"
                />
              </span>
            </div>
          </div>
          <div>
            <b-collapse id="collapse" v-model="visibleFilter" class="mt-2">
              <div class="px-2" >
                <b-row>
                  <b-col sm="4">
                    <b-form-group
                      :label="'Fecha inicio'"
                      label-for="filter-fechaini"
                    >
                      <flat-pickr
                        v-model="date_ini"
                        name="fecha_ini"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      :label="'Fecha fin'"
                      label-for="filter-fechafin"
                    >
                      <flat-pickr
                        v-model="date_fin"
                        name="fecha_ini"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      :label="'Búsqueda'"
                      label-for="filter-search"
                    >
                      <b-form-input
                        v-model="searchTerm"
                        :placeholder="'Búsqueda'"
                        type="text"
                        class="d-inline-block"
                        @input="handleSearch"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      :label="'Estado'"
                      label-for="filter-order"
                    >
                      <v-select
                        v-model="status"
                        label="name"
                        :filterable="false"
                        :searchable="false"
                        :options="selectStatus"
                      >
                        <template slot="option" slot-scope="option">
                          {{ statusJobsText[option.name] }}
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{statusJobsText[option.name] }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                <b-col sm="6" class="p-sm-2">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="mr-1 text-nowrap"
                        @click="download(project.id,'excel')"
                    >
                      Descargar lista Excel
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-collapse>
          </div>
          <b-table
            hover
            responsive
            :items="items"
            :fields="columns"
            :no-local-sorting="true"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
          >
            <template #cell(name)="data">
              <p class="m-0" >{{ data.item.name }}</p>
              <span v-if="data.item.category" class="font-small" >{{ data.item.category.name }}</span>
            </template>
            <template #cell(date_ini)="data">
              {{ TimestampToFlatPickr(data.item.date_ini) }}
            </template>
            <template #cell(date_fin)="data">
              {{ TimestampToFlatPickr(data.item.date_fin) }}
            </template>
            <template #cell(project)="data">
              <span v-if="data.item.project.name" >{{ data.item.project.name }}</span>
            </template>
            <template #cell(category)="data">
              <span v-if="data.item.category.name" >{{ data.item.category.name }}</span>
            </template>
            <template #cell(status)="data">
              <b-badge
                  :variant="statusJobsVariant[data.item.status]"
              >
                {{ statusJobsText[data.item.status] }}
              </b-badge>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link
                  :to="{ name: 'viewJob', params: { id: data.item.id} }"
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="EyeIcon" />
                </b-link>
                <b-link
                  :to="{ name: 'editJob', params: { id: data.item.id} }"
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-link>
                <span
                  @click="deleteJob( data.item.id, data.item.name)"
                  class="d-inline-block px-50 text-danger"
                >
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
          <div class="d-flex align-items-center justify-content-between mb-1 px-2">
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap ">
                {{ 'Por página' }}:
              </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="ml-50 mr-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> {{ 'Total' }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters,mapMutations } from 'vuex'
import {
  BBadge, BRow, BCol, BCard, BCollapse, BLink, BButton, BTable, BPagination, BFormSelect, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { FlatPickrToTimestamp, TimestampToFlatPickr } from '@/libs/helpers'
import { config } from '@/shared/app.config'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import axios from "axios";

export default {
  components: {
    BButton,
    BBadge,
    BLink,
    BRow,
    BCol,
    BCard,
    BTable,
    BPagination,
    BFormSelect,
    BCollapse,
    BFormGroup,
    vSelect,
    flatPickr,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      sortBy: '',
      sortDesc: '',
      statusProjectsVariant: config.statusProjectsVariant,
      statusProjectsText: config.statusProjectsText,
      statusJobsVariant: config.statusJobsVariants,
      statusJobsText: config.statusJobsText,
      currentPage: 1,
      pageLength: 10,
      dir: false,
      collapseInfo: false,
      projectPrepare: false,
      pages: ['10', '15', '25'],
      date_ini: '',
      date_fin: '',
      status: '',
      searchTerm: '',
      visibleFilter: false,
      columns: [
        {
          label: 'Nombre',
          key: 'name',
          sortable: true,
        },
        {
          label: 'Inicio',
          key: 'date_ini',
          sortable: true,
        },
        {
          label: 'Fin',
          key: 'date_fin',
          sortable: true,
        },
        {
          label: 'Estado',
          key: 'status',
        },
        {
          label: 'Horas',
          key: 'total_hours',
          sortable: true,
        },
        {
          label: 'Acciones',
          key: 'actions',
          class: 'column-actions',
        },
      ],
    }
  },
  watch: {
    sortBy() {
      this.currentPage = 1
      this.chargeData()
    },
    sortDesc() {
      this.currentPage = 1
      this.chargeData()
    },
    date_ini() {
      this.currentPage = 1
      this.chargeData()
    },
    date_fin() {
      this.currentPage = 1
      this.chargeData()
    },
    status() {
      this.currentPage = 1
      this.chargeData()
    },
  },
  computed: {
    ...mapGetters({
      project: 'projects/getProject',
      items: 'jobs/getItems',
      totalItems: 'jobs/getTotalItems',
      role: 'auth/getRole',
      selectStatus: 'jobs/getSelectStatus',
      delete: 'jobs/delete',
    }),
  },
  methods: {
    ...mapActions({
      getProject: 'projects/getProject',
      list: 'jobs/getListJobs',
      getSelectStatus: 'jobs/getSelectStatus',
    }),
   ...mapMutations({
      loading: "app/loading"
    }),
    TimestampToFlatPickr(f) {
      return TimestampToFlatPickr(f)
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    download(id,type) {
      this.loading(true)
      const filterDay = ''
      let filterDateIni = ''
      let filterDateFin = ''
      let user = ''
      if (this.date_ini) {
        filterDateIni = FlatPickrToTimestamp(this.date_ini)
      }
      if (this.date_fin) {
        filterDateFin = FlatPickrToTimestamp(this.date_fin)
      }
      if (this.user && this.user !== null) {
        user = this.user.id
      }
      let status = ''
      if (this.status && this.status !== null) {
        status = this.status
      }
      let client = ''
      if (this.client && this.client !== null) {
        client = this.client.id
      }
      let orderBy = ''
      if (this.sortBy) {
        const type = this.sortDesc ? 'desc' : 'asc'
        orderBy = {
          column: this.sortBy,
          type,
        }
      }
      axios({
        url: `${config.webApiBase}/tasks/list/download`, // your url
        data: {
          client_id: client,
          project_id: id,
          orderBy,
          status,
          user_id: user,
          date_ini: filterDateIni,
          date_fin: filterDateFin,
          search: this.searchTerm,
          type,
        },
        method: 'POST',
        responseType: 'blob', // important
      }).then(response => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data)

        // create "a" HTML element with href to file & click
        const link = document.createElement('a')
        link.href = href
        if (type === 'excel') {
          link.setAttribute('download', 'list.xlsx') // or any other extension
        } else {
          link.setAttribute('download', 'list.pdf') // or any other extension
        }
        document.body.appendChild(link)
        link.click()

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
        this.loading(false)
      })
    },
    deleteTask(id, name) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: 'Eliminar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(id)
          }
        })
    },
    chargeData() {
      let filterDateIni = ''
      let filterDateFin = ''
      if (this.date_ini) {
        filterDateIni = FlatPickrToTimestamp(this.date_ini)
      }
      if (this.date_fin) {
        filterDateFin = FlatPickrToTimestamp(this.date_fin)
      }
      let status = ''
      if (this.status && this.status !== null) {
        status = this.status
      }
      let orderBy = ''
      if (this.sortBy) {
        const type = (this.sortDesc) ? 'desc' : 'asc'
        orderBy = {
          column: this.sortBy,
          type,
        }
      }

      this.list({
        client: '',
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        date_ini: filterDateIni,
        date_fin: filterDateFin,
        status,
        orderBy,
        project: this.$route.params.id,
      })
    },
  },
  async created() {
    await this.getProject(this.$route.params.id)
    await this.chargeData()
    await this.getSelectStatus()
    this.projectPrepare = true
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
